import { ActionFactory } from '../../types';
import { DialogStep } from '../../viewModel/dialogViewModel/dialogViewModel';
import { SetDialogStep } from '../setDialogStep/setDialogStep';

export type SetIsDialogOpen = (isOpen: boolean) => void;

export const createSetIsDialogOpen: ActionFactory<
  SetIsDialogOpen,
  { setDialogStep: SetDialogStep }
> =
  ({ controllerConfig: { setProps }, viewModel, setDialogStep }) =>
  (isOpen) => {
    viewModel.dialogViewModel.isOpen = isOpen;

    setProps({ viewModel: { ...viewModel } });
    setDialogStep(DialogStep.FORM);
  };
