
  import userController from '/home/builduser/work/bdd178f9326b1ce3/packages/appointment-waitlist-form/src/components/AppointmentWaitlistForm/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/controllerWrapper.js';

  
const wrapController = null;


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://d1bbe4458f884c6ba40890087b9ca2f9@sentry.wixpress.com/5773',
    id: 'undefined',
    projectName: 'appointment-waitlist-form-31',
    teamName: 'bookings',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/bdd178f9326b1ce3/packages/appointment-waitlist-form/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = null;

  var defaultTranslations = {"waitlist-form.form-dialog-header-title":"Join Waitlist","waitlist-form.form-dialog-header-loading-service-details":"Loading service details...","waitlist-form.form-dialog-header-service-details-multi-service":"{numberOfServices} services","waitlist-form.form-dialog-header-service-details":"{serviceName} | {paymentDescription}","waitlist-form.service.payment.from-price":"From {price}","waitlist-form.form-dialog-success-title":"You've been added to the waitlist","waitlist-form.form-dialog-success-text":"Thanks for joining the waitlist.<br>We will be in touch soon.","waitlist-form.form-dialog-success-button":"Done","waitlist-form.waitlist-notification-text":"Can’t find the date or time you’re looking for? <Link>Join the waitlist</Link>","waitlist-form.settings.tabs.main":"Main","waitlist-form.settings.tabs.design":"Design","waitlist-form.settings.main.description":"Let your clients join the Appointment Waitlist if they can't find an available time they want to book.","waitlist-form.settings.main.title":"Appointment Waitlist","waitlist-form.settings.design.notification-background-color":"Background color & opacity","waitlist-form.settings.design.notification-link-text-color":"Link color","waitlist-form.settings.design.text-font":"Text","waitlist-form.settings.design.font-picker-title":"Choose a font","waitlist-form.settings.design.main-header":"Design","waitlist-form.settings.main.manage-button":"Manage Waitlist"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/bdd178f9326b1ce3/packages/appointment-waitlist-form/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Appointment Waitlist",
    componentName: "AppointmentWaitlistForm",
    appDefinitionId: "894e612f-f5b5-4641-aa40-696963c689d2",
    componentId: "133487e7-5c2e-42e9-aba7-c75789861e75",
    projectName: "appointment-waitlist-form",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/bdd178f9326b1ce3/packages/appointment-waitlist-form/src/components/AppointmentWaitlistForm/controller.ts",
  };

  export const controller = _controller
  export default controller;
