import { ActionFactory } from '../../types';
import { DialogStep } from '../../viewModel/dialogViewModel/dialogViewModel';

export type SetDialogStep = (step: DialogStep) => void;

export const createSetDialogStep: ActionFactory<SetDialogStep> =
  ({ controllerConfig: { setProps }, viewModel }) =>
  (step) => {
    viewModel.dialogViewModel.step = step;

    setProps({ viewModel: { ...viewModel } });
  };
