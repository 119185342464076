import { DialogStep } from '../viewModel/dialogViewModel/dialogViewModel';
import { AppointmentWaitlistFormViewModel } from '../viewModel/viewModel';

// for some reason the editor doesn't rerender after the set props call. so we need to create dummy view model for the widget conditional render
export const createDummyData = () => {
  const viewModel: AppointmentWaitlistFormViewModel = {
    dialogViewModel: { isOpen: false, step: DialogStep.SUCCESS },
  };

  const actions = {};

  return { viewModel, actions };
};
