import { ViewModelFactory } from '../types';
import {
  DialogViewModel,
  createDialogViewModel,
} from './dialogViewModel/dialogViewModel';

export type AppointmentWaitlistFormViewModel = {
  dialogViewModel: DialogViewModel;
};

export const createAppointmentWaitlistFormViewModel: ViewModelFactory<
  AppointmentWaitlistFormViewModel
> = (...args) => {
  return {
    dialogViewModel: createDialogViewModel(...args),
  };
};
