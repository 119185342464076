import { ActionFactory } from '../../types';
import { SetIsDialogOpen } from '../setIsDialogOpen/setIsDialogOpen';
import { bookingsCalendarClick } from '@wix/bi-logger-bookings-data/v2';

export type OpenDialog = () => void;

export const createOpenDialog: ActionFactory<
  OpenDialog,
  { setIsDialogOpen: SetIsDialogOpen }
> =
  ({ setIsDialogOpen, flowAPI: { bi } }) =>
  () => {
    setIsDialogOpen(true);

    bi?.report(
      bookingsCalendarClick({
        component: 'appointment waitlist slot',
        element: 'join waitlist',
      }),
    );
  };
