import { getPaymentDescription } from '@wix/bookings-calendar-catalog-viewer-mapper';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { AppointmentWaitlistFormContext, ViewModelFactory } from '../../types';

export type FormHeaderViewModel = {
  selectedDate: string;
  serviceDetails: string;
};

export const createFormHeaderViewModel: ViewModelFactory<
  FormHeaderViewModel
> = ({ context }) => {
  const {
    availabilityPreferences: { fromLocaleDate },
    flowAPI: {
      controllerConfig: {
        wixCodeApi: {
          window: { locale },
        },
      },
    },
  } = context;

  return {
    selectedDate: formatSelectedDate({
      locale,
      selectedDate: fromLocaleDate,
    }),
    serviceDetails: getServiceDetails({ context }),
  };
};

const formatSelectedDate = ({
  locale,
  selectedDate,
}: {
  selectedDate: string;
  locale: string;
}) => {
  return new DateTimeFormatter(locale).formatDayOfMonth(selectedDate);
};

const getServiceDetails = ({
  context: {
    availabilityPreferences: { services },
    flowAPI: {
      translations: { t },
      controllerConfig: {
        wixCodeApi: {
          window: { locale },
        },
      },
    },
    servicesMap,
  },
}: {
  context: AppointmentWaitlistFormContext;
}) => {
  const numberOfServices = services.length;

  if (numberOfServices > 1) {
    return t('waitlist-form.form-dialog-header-service-details-multi-service', {
      numberOfServices,
    });
  }

  const serviceId = services[0].serviceId;

  if (!servicesMap?.[serviceId]) {
    return t('waitlist-form.form-dialog-header-loading-service-details');
  }

  const service = servicesMap[serviceId];
  const serviceName = service.name;
  const paymentDescription = getPaymentDescription({
    payment: service.payment!,
    regionalSettingsLocale: locale,
    paymentFormatters: {
      variedPrice: ({ minPrice }) =>
        t('waitlist-form.service.payment.from-price', { price: minPrice }),
    },
  });

  return t('waitlist-form.form-dialog-header-service-details', {
    serviceName,
    paymentDescription,
  });
};
