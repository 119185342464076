import { ActionFactory } from '../types';
import { CloseDialog, createCloseDialog } from './closeDialog/closeDialog';
import {
  OnFormSubmitSuccess,
  createOnFormSubmitSuccess,
} from './onFormSubmitSuccess/onFormSubmitSuccess';
import { OpenDialog, createOpenDialog } from './openDialog/openDialog';
import {
  SetDialogStep,
  createSetDialogStep,
} from './setDialogStep/setDialogStep';
import { createSetIsDialogOpen } from './setIsDialogOpen/setIsDialogOpen';

export type AppointmentWaitlistFormActions = {
  closeDialog: CloseDialog;
  openDialog: OpenDialog;
  setDialogStep: SetDialogStep;
  onFormSubmitSuccess: OnFormSubmitSuccess;
};

export const createAppointmentWaitlistFormActions: ActionFactory<
  AppointmentWaitlistFormActions
> = (args) => {
  const setDialogStep = createSetDialogStep(args);
  const setIsDialogOpen = createSetIsDialogOpen({ ...args, setDialogStep });

  return {
    closeDialog: createCloseDialog({ ...args, setIsDialogOpen }),
    openDialog: createOpenDialog({ ...args, setIsDialogOpen }),
    setDialogStep: createSetDialogStep(args),
    onFormSubmitSuccess: createOnFormSubmitSuccess({ ...args, setDialogStep }),
  };
};
