import { WaitlistSubmission } from '@wix/appointment-waitlist-common';
import { ActionFactory } from '../../types';
import { DialogStep } from '../../viewModel/dialogViewModel/dialogViewModel';
import { SetDialogStep } from '../setDialogStep/setDialogStep';
import { type FormSubmission } from '@wix/ambassador-forms-v4-submission/types';
import { bookingsCalendarAppointmentWaitlistRequestSaveSrc16Evid1234 } from '@wix/bi-logger-bookings-data/v2';

export type OnFormSubmitSuccess = (submission: FormSubmission) => void;

export const createOnFormSubmitSuccess: ActionFactory<
  OnFormSubmitSuccess,
  { setDialogStep: SetDialogStep }
> =
  ({ flowAPI: { bi }, setDialogStep }) =>
  (submission) => {
    setDialogStep(DialogStep.SUCCESS);

    const { contactId, id, submissions } = submission;
    const waitlistSubmission = submissions as WaitlistSubmission;
    const {
      note,
      time_of_day,
      service1,
      service2,
      service3,
      service4,
      service5,
    } = waitlistSubmission;
    const services = [service1, service2, service3, service4, service5].filter(
      Boolean,
    );
    const timeOfDay = time_of_day?.join(',')?.toLowerCase();

    bi?.report(
      bookingsCalendarAppointmentWaitlistRequestSaveSrc16Evid1234({
        success: true,
        note_added: Boolean(note),
        save_type: 'create_new',
        serviceId: services.join(','),
        contactId: contactId || undefined,
        numberOfSessions: services.length,
        TimeofDay: timeOfDay,
        submissionId: id!,
        requestOrigin: 'UoU',
      }),
    );
  };
